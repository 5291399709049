import { gql } from '@apollo/client';

export const QUERIES = {
    SHEETS_FOR_STATUS: gql`
     query ColtSheets($statuses: [String]) {
             coltSheets(statuses: $statuses) {
                 id
                 Name
                 sheet_number
                 Sender
                 sent_date
                 Title
                 Status
                 Owner
                 create_date
                 file_id
                 Contacts_fk
             }
         }
   `,
   CREATE_SHEET: gql`
    mutation CreateColtSheet($sheet: SheetInput!) {
        createColtSheet(sheet: $sheet) {
            Owner
        }
    }
   `,
   UPDATE_SHEET: gql`
    mutation UpdateColtSheet($sheet: SheetInput!, $senderMail: String) {
        updateColtSheet(sheet: $sheet, senderMail: $senderMail) {
            Owner
        }
    }
   `,
   DELETE_SHEET: gql`
    mutation DeleteColtSheet($sheet: SheetInput!) {
        deleteColtSheet(sheet: $sheet) {
            Owner
        }
    }
   `,
   GROUPS_FOR_USER: gql`
    query GoogleGroups($user: String) {
        googleGroups(user: $user) {
            id
            name
        }
    }
   `,
   CONTACTS: gql`
    query ColtContacts {
        coltContacts {
            id
            Email
        }
    }
   `,
   CREATE_COLT_CONTACT: gql`
    mutation CreateColtContact($coltContact: ContactInput!) {
        createColtContact(coltContact: $coltContact) {
            id
            Email
        }
    }
   `,
   UPDATE_COLT_CONTACT: gql`
    mutation UpdateColtContact($coltContact: ContactInput!) {
        updateColtContact(coltContact: $coltContact) {
            id
            Email
        }
    }
   `,
   DELETE_COLT_CONTACT: gql`
    mutation DeleteColtContact($coltContact: ContactInput!) {
        deleteColtContact(coltContact: $coltContact) {
            id
            Email
        }
    }
   `,
   CREATE_SHEETS_REPORT: gql`
    mutation CreateSheetsReport {
        createSheetsReport
    }
   `,
   EMPLOYEE_BY_MAIL: gql`
    query EmployeeByMail($email: String!) {
        employeeByMail(email: $email) {
            id
            email
            securityRole {
                id
                name
                code
            }
        }
    }
   `,
}