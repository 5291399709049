import { useMutation, useQuery } from '@apollo/client';
import { Button, ButtonBase, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Avatar, makeStyles } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { Autocomplete } from '@material-ui/lab';
import React, { useState, useEffect } from 'react';
import { QUERIES } from '../../api/queries';
import urls from '../../api/urls.json';
import EditIcon from '@material-ui/icons/Edit';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Divider from '@material-ui/core/Divider';
import { showErrorToast, showInfoToast } from '../util/toasts';
import CheckIcon from '@material-ui/icons/Check';
import UndoIcon from '@material-ui/icons/Undo';
import DeleteIcon from '@material-ui/icons/Delete';
import { confirmAlert } from 'react-confirm-alert';
import SendIcon from '@material-ui/icons/Send';
import { useGlobal } from 'reactn';

const useStyles = makeStyles({
    avatar: {
        color: "grey",
        backgroundColor: "#f8f8f8"
    },
    modal: {
        position: 'absolute',
        width: 600,
        height: "auto",
        padding: "5px 50px 20px 50px",
        backgroundColor: "white",
        border: "none",
        boxShadow: 'none',
        borderRadius: 10,
        outline: 0,
    },
    modalInputs: {
        display: "block",
        marginTop: 40,
    },
    modalBtnCancel: {
        color: "red"
    },
    modalBtnOk: {
        marginLeft: 15,
        backgroundColor: "green",
        color: "white",
        "&:hover": {
            backgroundColor: "darkgreen !important"
        }
    },
    input:{
        width:"100%"
    }
});

const SheetTable = props => {
    const classes = useStyles();

    const [authUser, setAuthUser] = useGlobal('authUser');
    const [sheets, setSheets] = useState([]);
    const [filteredSheets, setFilteredSheets] = useState([]);
    const [owners, setOwners] = useState([]);
    const [filter, setFilter] = useState({owner: 'Alle', status: 'Open'});
    //Title modal
    const [showModal, setShowModal] = useState(false);
    const [selectedModalItem, setSelectedModalitem] = useState();
    const [titleModalLoading, setTitleModalLoading] = useState(false);
    const [titleModalInput, setTitleModalInput] = useState("");
    const [UpdateMutationHandler] = useMutation(QUERIES.UPDATE_SHEET);
    const [DeleteMutationHandler] = useMutation(QUERIES.DELETE_SHEET);

    const { loading:contactsLoading, error, data:contactsRes, refetch:refetchContacts } = useQuery(QUERIES.CONTACTS);

    const cols = [
        { field: 'id', headerName: 'Nummer' },
        {
            field: 'file_id', headerName: 'Vorderingsstaat', renderCell: ({ row }) => {
                const url = urls["google-sheet-base-url"] + row.file_id;
                return (<a href={url} target='_blank'>{row.Name}</a>)
            },
            width: 140
        },
        {field: 'Title', headerName: 'Titel', width: 400, renderCell: ({ row })=>{
            return (
                <div style={{ width: "100%", height: "100%", padding: 0, margin: 0 }}>
                    <p style={{ float: "left", height: "100%", marginTop: 0 }}>{row.Title}</p>
                    <strong style={{ float: "right" }}><ButtonBase style={{ margin: "auto", borderRadius: "25px" }} onClick={()=>openTitleModal(row)} ><Avatar className={classes.avatar}>
                        <EditIcon />
                    </Avatar></ButtonBase></strong>
                </div>
            )
        }},
        { field: 'Contacts_fk', headerName: 'Contact', renderCell: ({ row })=>{
            const contactId = row.Contacts_fk||0;
            return (
                <Select
                value={contactId}
                onChange={evt=>handleContactChange(row.id, evt.target.value)}
                >
                <MenuItem value={0}>Geen selectie</MenuItem>
                {contactsRes && contactsRes.coltContacts && contactsRes.coltContacts.length > 0 && contactsRes.coltContacts.map(contact=>{
                    return <MenuItem value={contact.id}>{contact.Email}</MenuItem>;
                })
                }
                </Select>
            );
        }, width: 200 },
        {
            field: 'create_date', headerName: 'Aangemaakt', renderCell: ({ value }) => {
                return new Date(value).toLocaleDateString('nl-BE');
            }, width: 140
        },
        !!props.showSenderColumns?{
            field: 'sent_date', headerName: 'Verzonden', renderCell: ({ value }) => {
                if(!value||value==0) return "";
                return new Date(value).toLocaleDateString('nl-BE');
            }, width: 200
        }:[],
        !!props.showSenderColumns?{field: 'Sender', headerName: 'Afzender', width: 200}:[],
        { field: 'Owner', headerName: 'Eigenaar', width: 200 },
        !!props.showCreateStatusbtn?{ field: 'id', headerName: 'Indienen', renderCell: ({ row }) => {
            const iconToUse = row.Status=="Open"?<CheckIcon />:<UndoIcon />;
            const newStatus = row.Status=="Open"?"Te Verzenden":"Open";
            return (
                <ButtonBase style={{ margin: "auto", borderRadius: "25px" }} onClick={()=>handleStatusChange(row.id, newStatus)}>
                    <Avatar className={classes.avatar}>
                            {iconToUse}
                    </Avatar>
                </ButtonBase>
            );
        }, width: 90 }:[],
        !!props.showSendPageContent?{ field: 'id', headerName: 'Verzenden', renderCell: ({ row }) => {
            const iconToUse = row.Status=="Te Verzenden"?<SendIcon />:<UndoIcon />;
            const newStatus = row.Status=="Te Verzenden"?"Verzonden":"Te Verzenden";
            return (
                <ButtonBase style={{ margin: "auto", borderRadius: "25px" }} onClick={()=>{
                    if(newStatus=="Verzonden"&&!row.Contacts_fk){
                        showErrorToast("Er is geen contact opgegeven.");
                        return;
                    }
                    handleStatusChange(row.id, newStatus)
                }}>
                    <Avatar className={classes.avatar}>
                            {iconToUse}
                    </Avatar>
                </ButtonBase>
            );
        }, width: 105 }:[],
        !!props.showReportPageContent?{ field: 'id', headerName: 'Terugzetten', renderCell: ({ row }) => {
            const iconToUse = <UndoIcon />;
            const newStatus = "Verzonden";
            if(row.Status != "Afgesloten") return <p>Verzonden</p>;
            return (
                <ButtonBase style={{ margin: "auto", borderRadius: "25px" }} onClick={()=>{
                    handleStatusChange(row.id, newStatus);
                }}>
                    <Avatar className={classes.avatar}>
                            {iconToUse}
                    </Avatar>
                </ButtonBase>
            );
        }, width: 105 }:[],
        { field: 'id', headerName: 'Verwijderen', width: 110, renderCell: ({row})=>{
            return (
                <ButtonBase style={{ margin: "auto", borderRadius: "25px" }} onClick={()=>deleteSheet(row)} ><Avatar className={classes.avatar}>
                        <DeleteIcon />
                </Avatar></ButtonBase>
            )
        } },
    ]

    useEffect(() => {
        setSheets(props.sheets);
        let tempOwners = ['Alle'];
        props.sheets.forEach(el => {
            if (tempOwners.indexOf(el.Owner) === -1) {
                tempOwners.push(el.Owner);
            }
        });
        setOwners(tempOwners);
        // filterSheets();
    }, [props.sheets]);

    useEffect(() => {
        filterSheets();
    }, [filter, sheets])

    const filterSheets = () => {
        let res = [...sheets];
        console.log(filter);
        if (filter.owner !== 'Alle') {
            res = res.filter(el => el.Owner === filter.owner)
        }
        if (filter.status !== 'Alle') {
            res = res.filter(el => el.Status === filter.status)
        }
        setFilteredSheets(res);
    }

    const handleStatusChange=async( id , newStatus)=>{
        try{
            if(!!props.setCreateLoading)props.setCreateLoading(true);
            if(!!props.setSendLoading)props.setSendLoading(true);

            await UpdateMutationHandler({ variables:{ sheet:{id, Status: newStatus}, senderMail: authUser.profileObj.email } });
            let sheetsArrCopy = JSON.parse(JSON.stringify([...sheets]));
            const index = sheetsArrCopy.findIndex((obj => obj.id == id));
            sheetsArrCopy[index].Status = newStatus;
            setSheets(sheetsArrCopy);
            showInfoToast("Status aangepast");
        }catch(err){
            showErrorToast("Er ging iets mis bij het aanpassen van de status.");
        }

        if(!!props.setCreateLoading)props.setCreateLoading(false);
        if(!!props.setSendLoading)props.setSendLoading(false);
    }

    const deleteSheet=async(row)=>{
        confirmAlert({
            title: `${row.sheet_number} - ${row.Name}`,
            message: `Bent u zeker om deze sheet te verwijderen?`,
            buttons: [
              {
                label: 'Ja',
                onClick: async() =>{
                    try{
                        await DeleteMutationHandler({ variables:{sheet:{id: row.id}} });
                        let sheetsArrCopy = JSON.parse(JSON.stringify([...sheets]));
                        const index = sheetsArrCopy.findIndex((obj => obj.id == row.id));
                        sheetsArrCopy.splice(index, 1);
                        setSheets(sheetsArrCopy);
            
                        showInfoToast("Succesvol verwijderd.");
                    }catch(err){
                        showErrorToast("Verwijderen is mislukt, probeer opnieuw.");
                    }
                }
              },
              { label: 'Nee' }
            ]
          });
    }

    const handleContactChange=async(sheetId, contactId)=>{
        if(contactId==0) contactId=null;
        try{
            await UpdateMutationHandler({ variables:{sheet:{id: sheetId, Contacts_fk: contactId}} });

            let sheetsArrCopy = JSON.parse(JSON.stringify([...sheets]));
            const index = sheetsArrCopy.findIndex((obj => obj.id == sheetId));
            sheetsArrCopy[index].Contacts_fk = contactId;
            setSheets(sheetsArrCopy);

            showInfoToast("Contact aangepast");
        }catch(err){
            showErrorToast("Er ging iets mis bij het aanpassen van het contact.")
        }
    }

    const openTitleModal=(sheet)=>{
        setSelectedModalitem(sheet);
        setTitleModalInput(sheet.Title);
        setShowModal(true);
    }

    const handletTitleModalOk=async()=>{
        setTitleModalLoading(true);
        try{
            if(titleModalInput && titleModalInput!= ""){
                await UpdateMutationHandler({ variables:{sheet:{id: selectedModalItem.id, Title: titleModalInput}} });
                let sheetsArrCopy = JSON.parse(JSON.stringify([...sheets]));
                const index = sheetsArrCopy.findIndex((obj => obj.id == selectedModalItem.id));
                sheetsArrCopy[index].Title = titleModalInput;
                setSheets(sheetsArrCopy);
                showInfoToast("Titel succesvol aangepast");
            }else{
                showErrorToast("Geef een geldige titel.");
            }
            setTitleModalLoading(false);
            setShowModal(false);
        }catch(err){
            showErrorToast("Er ging iets mis bij het bewerken van de titel.");
            setTitleModalLoading(false);
            setShowModal(false);
        }
    }

    const handleTitleModalCancel=()=>{
        if(!titleModalLoading){
            setSelectedModalitem(null);
            setTitleModalInput("");
            setShowModal(false);
        }
    }

    const modalBody = ( 
        <Fade in={showModal}>
            <div style={{top: `20%`, left: `50%`, transform: `translate(-50%, -20%)`}} className={classes.modal}>
                <h2 id="simple-modal-title">Titel aanpassen voor: {selectedModalItem?selectedModalItem.Name:"/"}</h2>
                <Divider />
                
                {/* content of the modal */}
                <div style={{width: "100%"}}>
                    <TextField style={{width: "100%"}} autoFocus={true} className={classes.modalInputs} defaultValue={selectedModalItem?selectedModalItem.Title:""} onBlur={(evt)=>setTitleModalInput(evt.target.value)} InputProps={{className: classes.input}} />
                </div>

                <Divider style={{marginTop: 40}} />
                <div style={{marginTop: 20, float: "right"}}>
                    <Button disabled={titleModalLoading} onClick={handleTitleModalCancel} className={classes.modalBtnCancel}>Annuleren</Button>
                    <Button disabled={titleModalLoading} onClick={handletTitleModalOk} className={classes.modalBtnOk} variant="contained">Aanpassen</Button>
                </div>
            </div>
        </Fade>
    );

    return (
        <>
        <Grid
            container
            direction="column"
            justify="space-between"
            alignItems="center"
            spacing={4}>
            <Grid item>
                {/* <Autocomplete
                    // options={projects}
                    getOptionLabel={(option) => option.project_id}
                    style={{ width: 200, margin: 8 }}
                    // value={filter.projectId}
                    onChange={(event, newValue) => {
                        // setFilter(old => ({ ...old, projectId: newValue ? newValue : { project_id: '' } }));
                    }}
                    renderInput={(params) => <TextField {...params} label="ID" variant="outlined" />}
                />
                <Autocomplete
                    // options={projects}
                    getOptionLabel={(option) => option.name}
                    style={{ width: 400, margin: 8 }}
                    // value={filter.projectName}
                    onChange={(event, newValue) => {
                        // setFilter(old => ({ ...old, projectName: newValue ? newValue : { name: '' } }));
                    }}
                    renderInput={(params) => <TextField {...params} label="Naam" variant="outlined" />}
                /> */}
                {props.actionButton && props.actionButton.title && props.actionButton.callback &&
                <Button style={{position: "absolute", left: "5vw", marginLeft: -5, color: "blue", backgroundColor: "white"}}
                variant="contained"
                disabled={props.actionButton.loading}
                onClick={props.actionButton.callback}>
                {props.actionButton.title}</Button>
                }
                <FormControl>
                    <InputLabel id="demo-simple-select-label">Eigenaar</InputLabel>
                    <Select style={{width: 200, marginRight: 16}}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filter.owner}
                        onChange={e => {
                            setFilter(old => ({...old, owner: e.target.value}))
                        }}
                    >
                        {
                           owners.map((el, index) => (
                            <MenuItem key={el + index} value={el}>{el}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                    <Select style={{width: 200, marginRight: 16}}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filter.status}
                        onChange={e => {
                            setFilter(old => ({...old, status: e.target.value}))
                        }}
                    >
                        {/* <MenuItem key='default' value={'Alle'}>Alle</MenuItem> */}
                        {
                           props.statusOptions.map((el, index) => (
                            <MenuItem key={el + index} value={el}>{el}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <Grid item>
                    <div style={{ height: '80vh', width: '90vw' }}>
                        <DataGrid key={(filteredSheets && filteredSheets.length > 0) ? filteredSheets : []} columns={props.cols ? props.cols : cols} rows={[...filteredSheets]} sortModel={[{ field: 'create_date', sort: 'desc' }]}></DataGrid>
                    </div>
                </Grid>
            </Grid>
        </Grid>

        <Modal
        open={showModal}
        onClose={handleTitleModalCancel}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
        timeout: 500,
        }}>
        { modalBody}
        </Modal>

        </>
        )
}

export default SheetTable;