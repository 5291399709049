import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import drawerLogo from '../../assets/coltDrawerBg.JPEG';
import ageresLogo from '../../assets/ageresLogo.png';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useGlobal } from 'reactn';

const ColtDrawer = ({ showDrawer, toggleDrawer }) => {
    const history = useHistory();

    const [currentEmployee] = useGlobal('currentEmployee');

    const handleHistory=(path)=>{
      history.push(path);
      toggleDrawer(false);
    }

    const getBackgroundColor=(path)=>{
      if(path == window.location.pathname)
        return "WhiteSmoke";
      return "transparent";
    }

    return(
        <React.Fragment key={"left"}>
        <Drawer anchor={"left"} open={showDrawer} onClose={()=>toggleDrawer(false)}>
          <div style={{width: 312, height: 200, background: `url(${drawerLogo})`, backgroundPosition: "center", color: "white" }}>
            <p style={{margin: 15, fontSize: 20, float: "left"}}>Colt Fiber Works</p>
            <IconButton style={{float: "right", margin: 5}} edge="start" color="inherit" aria-label="menu" onClick={()=>toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </div>
          <img src={ageresLogo} alt="DrawerLogo" style={{width: 95, height: 80, position: "relative", left: 107, top: -45}} />
          <List>
            <ListItem button key={"Aanmaken"} style={{background: getBackgroundColor("/")}}>
              <ListItemText primary={"Aanmaken"} onClick={()=>handleHistory("/")} />
            </ListItem>
            {!!currentEmployee&&["ADMINISTRATOR"].includes(currentEmployee.securityRole.code)&& <ListItem button key={"Contacts"} style={{background: getBackgroundColor("/contacts")}}>
              <ListItemText primary={"Contacts"} onClick={()=>handleHistory("/contacts")} />
            </ListItem>}
            {!!currentEmployee&&["ADMINISTRATOR", "PROJECT_MANAGER"].includes(currentEmployee.securityRole.code)&&  <ListItem button key={"Verzenden"} style={{background: getBackgroundColor("/send")}}>
              <ListItemText primary={"Verzenden"} onClick={()=>handleHistory("/send")} />
            </ListItem>}
            {!!currentEmployee&&["ADMINISTRATOR", "PROJECT_MANAGER"].includes(currentEmployee.securityRole.code)&& <ListItem button key={"Rapporteren"} style={{background: getBackgroundColor("/report")}}>
              <ListItemText primary={"Rapporteren"} onClick={()=>handleHistory("/report")} />
            </ListItem>}
          </List>
        </Drawer>
      </React.Fragment>
    );
}

export default ColtDrawer;