import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Divider } from '@material-ui/core';
import { useEffect, useState } from 'reactn';
import { showErrorToast } from '../util/toasts';

const ContactsDialog = ({ contactDialogVisible, selectedContact, setSelectedAndDialog, createContact, updateContact }) => {

  const [mailInput, setMailInput] = useState("");

  useEffect(()=>{
    if(!!selectedContact&&!!selectedContact.Email)
      setMailInput(selectedContact.Email);
  },[selectedContact]);

  const checkAndSubmit=async()=>{
      if(!!mailInput&&mailInput!=""){
        if(!!selectedContact)
          await updateContact(mailInput);
        else await createContact(mailInput);
      }else{
        showErrorToast("Vul een Email in.");
      }
      setMailInput("");
  }

  const close=()=>{
    setSelectedAndDialog(null, false);
    setMailInput("");
  }

   return(
    <Dialog open={contactDialogVisible} onClose={close}>
    <DialogTitle id="form-dialog-title">Contact {selectedContact?"bewerken":"toevoegen"}</DialogTitle>
    <DialogContent>
        <Divider style={{marginTop: -5, marginBottom: 15}} />
      <TextField
        autoFocus
        id="name"
        label="Email"
        type="email"
        fullWidth
        defaultValue={mailInput}
        onChange={(e)=>setMailInput(e.target.value)}
        InputProps={{style:{width: 500}}}
      />
    </DialogContent>
    <DialogActions style={{marginTop: 25}}>
      <Button color="secondary" onClick={close}>
        Annuleren
      </Button>
      <Button color="primary" onClick={checkAndSubmit}>
        {selectedContact?"bewerken":"toevoegen"}
      </Button>
    </DialogActions>
  </Dialog>
   )
}


export default ContactsDialog;