import React, { useEffect } from 'react';
import { useGlobal } from 'reactn';
import { GoogleLogin } from 'react-google-login';
import axios from 'axios';
import { Card, Grid, CardContent, Typography } from '@material-ui/core';
import { useLazyQuery } from '@apollo/client';
import { QUERIES } from '../../api/queries';


const Login = props => {

    const [authUser, setAuthUser] = useGlobal('authUser');
    const [currentEmployee, setCurrentEmployee] = useGlobal('currentEmployee');
    const [requestedRoute, setRequestedRoute] = useGlobal('requestedRoute');

    const [collectEmployee] = useLazyQuery(QUERIES.EMPLOYEE_BY_MAIL, {
        onCompleted: (data)=>{
            setCurrentEmployee(data.employeeByMail);
        },
        fetchPolicy: "network-only"
    });

    useEffect(() => {
        if (!!authUser&&!!currentEmployee) {
            console.log('authuser: ', authUser);
            props.history.replace(requestedRoute? requestedRoute.pathname: '/');
        }
    }, [authUser, currentEmployee]);

    const collectEmployeeAndSetUser=async(responseGoogle)=>{
        collectEmployee({variables:{email: responseGoogle.profileObj.email}});
        setAuthUser(responseGoogle);
    }

    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center">
            <Card
                variant="outlined">
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center">
                    <CardContent>
                        <Typography color="textSecondary" gutterBottom>
                            Inloggen met je Ageres-account
        </Typography>
                    </CardContent>
                    <GoogleLogin
                        // responseType="code"
                        className="googleButton"
                        clientId="944762034876-hs398hn6do3e01uh4ih1upvk24mf9iq0.apps.googleusercontent.com"
                        buttonText="Login"
                        // approvalPrompt="force"
                        // prompt='consent'
                        // offline={true}
                        onSuccess={async (responseGoogle) => {
                            collectEmployeeAndSetUser(responseGoogle);
                        }}
                        onFailure={(responseGoogle) => console.log(responseGoogle)}
                        cookiePolicy={'single_host_origin'}
                        isSignedIn={true}
                        // scope='https://www.googleapis.com/auth/admin.directory.group'
                        scope='https://www.googleapis.com/auth/admin.directory.user.readonly https://www.googleapis.com/auth/admin.directory.group.readonly https://www.googleapis.com/auth/gmail.compose'
                    />
                </Grid>
            </Card>
        </Grid>)
}

export default Login;