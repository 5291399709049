import { AppBar, IconButton, Typography, Toolbar, Grid } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';
import { getGlobal, useGlobal } from 'reactn';

import Logout from '../Logout/Logout';

const HeaderMenu = props => {
    const authUser = getGlobal('authUser');
    return (
        <AppBar position="static">
            <Toolbar variant="dense">
                <IconButton edge="start" color="inherit" aria-label="menu" onClick={()=>props.toggleDrawer()}>
                    <MenuIcon />
                </IconButton>
                <Grid
                    container
                    spacing={6}
                    direction='row'
                    alignItems='center'
                    justify='space-between'

                >
                    <Grid item>
                        <Typography variant="h6" color="inherit">
                            Colt Fiberworks
    </Typography>
                    </Grid>
                    {!!authUser && (
                        <Grid item>
                        <Logout />
                    </Grid>
                    )}
                </Grid>
            </Toolbar>
        </AppBar>
    );
}

export default HeaderMenu;