import React from 'react';
import {GoogleLogout } from 'react-google-login';
import { Redirect } from 'react-router-dom';
import {useGlobal} from 'reactn';

const Logout = props => {
    const [authUser, setAuthUser] = useGlobal('authUser');
    const [requestedRoute, setRequestedRoute] = useGlobal('requestedRoute');
    if (!!authUser) {
        return (
            <GoogleLogout
            clientId="944762034876-hs398hn6do3e01uh4ih1upvk24mf9iq0.apps.googleusercontent.com"
            buttonText="Afmelden"
            onLogoutSuccess={() => {
                // setRequestedRoute({pathname: '/login'});
                setAuthUser(null);
            }
          }
          >
          </GoogleLogout>
        )
    }
    else return (<div></div>)
}

export default Logout;