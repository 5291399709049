import { useMutation, useQuery } from '@apollo/client';
import { Backdrop, CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { QUERIES } from '../../api/queries';
import SheetTable from '../SheetTable/SheetTable';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

const CreatePage = props => {
    const classes = useStyles();
    const history = useHistory();

    const [authUser, setAuthUser] = useGlobal('authUser');
    const [currentEmployee] = useGlobal('currentEmployee');
    const [loading, setLoading] = useState(false);

    const statusOptions = ['Open', 'Te Verzenden'];

    const { loading:queryLoading, error, data:sheetRes, refetch } = useQuery(QUERIES.SHEETS_FOR_STATUS, {
        variables: { statuses: statusOptions }
    });

    const [onCreateMutationHandler] = useMutation(QUERIES.CREATE_SHEET);

    const actionButton = {
        title: "Nieuw",
        loading: false,
        callback: ()=>{
            createNewSheet();
        }
    };

    const createNewSheet=async()=>{
        setLoading(true);
        await onCreateMutationHandler({ variables:{sheet:{Owner: authUser.profileObj.email}} });
        await refetch();
        setLoading(false);
    }

    if(!!currentEmployee&&!["ADMINISTRATOR", "PROJECT_MANAGER", "COWORKER"].includes(currentEmployee.securityRole.code)){
        return (<p>U heeft geen toegang tot deze applicatie.</p>)
    }

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={4}>
            <Grid item>
                <Typography variant='h2'>Aanmaken</Typography>
                {/* <Dialog open={projectDetailsVisible}>
                <ProjectDetails
                    visible={projectDetailsVisible}
                    projectId={selectedProject? selectedProject.id: null}
                    handleClose={() => setProjectDetailsVisible(false)}
                    handleSave={async () => {
                        setProjectDetailsVisible(false)
                        // refetch().then(res => {
                        //     console.log('Refetch res: ', res)
                        //     handleData(res.data);
                        // })
                        }} />
            </Dialog> */}
            </Grid>
            {!!sheetRes && (
                <Grid item>
                    <SheetTable sheets={sheetRes.coltSheets} statusOptions={statusOptions} actionButton={actionButton} showCreateStatusbtn={true} setCreateLoading={setLoading} />
                </Grid>
            )}

            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Grid>
    )
}

export default CreatePage;