import { useMutation, useQuery } from '@apollo/client';
import { getGlobal, useGlobal, useState } from 'reactn';
import { Backdrop, CircularProgress, Grid, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { QUERIES } from '../../api/queries';
import SheetTable from '../SheetTable/SheetTable';
import { showErrorToast, showInfoToast } from '../util/toasts';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

const ReportPage = props => {
    const classes = useStyles();
    const history = useHistory();

    const statusOptions = ['Verzonden', 'Afgesloten'];
    const [loading, setLoading] = useState(false);
    const [currentEmployee] = useGlobal('currentEmployee');

    const sheetRes = useQuery(QUERIES.SHEETS_FOR_STATUS, {
        variables: { statuses: statusOptions }
    });

    const groupRes = useQuery(QUERIES.GROUPS_FOR_USER, {
        variables: {
            user: getGlobal().authUser ? getGlobal().authUser.getBasicProfile().getEmail() : null
        }
    });

    const [createSheetsReportMutationHandler] = useMutation(QUERIES.CREATE_SHEETS_REPORT);

    const actionButton = {
        title: "Rapport",
        loading: false,
        callback: ()=>{
            createReport();
        }
    };

    useEffect(() => {
        console.log(groupRes.data)
    }, [groupRes.data]);

    const createReport=async()=>{
        try{
            setLoading(true);
            await createSheetsReportMutationHandler();
            showInfoToast("Rapport succesvol verwerkt.");
        }catch(err){
            showErrorToast("Kon rapport niet samenstellen, probeer later opnieuw.");
        }
        await sheetRes.refetch();
        setLoading(false);
    }

    if(!!currentEmployee&&!["ADMINISTRATOR", "PROJECT_MANAGER"].includes(currentEmployee.securityRole.code)){
        history.push("/");
    }

    if (sheetRes.loading && groupRes.loading) {
        return (
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                spacing={4}>
                <Grid item>
                    <Typography variant='h2'>Rapporteren</Typography>
                    <CircularProgress />
                </Grid>
            </Grid>)
    }

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={4}>
            <Grid item>
                <Typography variant='h2'>Rapporteren</Typography>
                {/* <Dialog open={projectDetailsVisible}>
                <ProjectDetails
                    visible={projectDetailsVisible}
                    projectId={selectedProject? selectedProject.id: null}
                    handleClose={() => setProjectDetailsVisible(false)}
                    handleSave={async () => {
                        setProjectDetailsVisible(false)
                        // refetch().then(res => {
                        //     console.log('Refetch res: ', res)
                        //     handleData(res.data);
                        // })
                        }} />
            </Dialog> */}
            </Grid>
            {!!sheetRes.data && (
                <Grid item>
                    <SheetTable sheets={sheetRes.data.coltSheets} statusOptions={statusOptions} showSenderColumns={true} actionButton={actionButton} showReportPageContent={true} setCreateLoading={setLoading} />
                </Grid>
            )}

            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Grid>
    )
}

export default ReportPage;