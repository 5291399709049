import React from "react";
import { Route, Redirect } from 'react-router-dom';
import { useGlobal } from "reactn";

/**
 * privateRoute.js
 * Wrapper for react Route component.
 * Redirects to the login component when there is no currentUser (and hence no auth).
 * Updates the global state via reducer to keep a reference of the requested route,
 * so the user will be redirected there when he ultimately logs in.
 */

const PrivateRoute = ({ component: RouteComponent, location: requestedPath, ...rest }) => {
  const [authUser, setAuthUser] = useGlobal('authUser');
  const [requestedRoute, setRequestedRoute] = useGlobal('requestedRoute');
  (!authUser && !requestedRoute) &&  setRequestedRoute(requestedPath);
  return (
    <Route {...rest} render={routerProps => !!authUser ? (
      <RouteComponent {...routerProps} />
    ) : (
        <Redirect to={'/login'} />
      )} />
  );
};

export default PrivateRoute;