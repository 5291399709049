import { useMutation, useQuery } from '@apollo/client';
import { Avatar, ButtonBase, Grid, makeStyles, IconButton, Typography, CircularProgress, Backdrop } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import React from 'react';
import { useEffect, useGlobal, useState } from 'reactn';
import { QUERIES } from '../../api/queries';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { confirmAlert } from 'react-confirm-alert';
import ContactsDialog from './ContactsDialog';
import { showErrorToast, showInfoToast } from '../util/toasts';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme)=>({
    avatar: {
        color: "grey",
        backgroundColor: "#f8f8f8"
    },
    backdrop: {
        zIndex: theme.zIndex.modal + 1,
        color: '#fff',
    },
}));

const ContactsPage = props => {
    const classes = useStyles();
    const history = useHistory();

    const [currentEmployee] = useGlobal('currentEmployee');
    const [contacts, setContacts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [contactDialogVisible, setContactDialogVisible] = useState(false);
    const [selectedContact, setSelectedContact] = useState(null);

    const { loading:queryLoading, error, data, refetch } = useQuery(QUERIES.CONTACTS);
    const [createContactMutation] = useMutation(QUERIES.CREATE_COLT_CONTACT);
    const [updateContactMutation] = useMutation(QUERIES.UPDATE_COLT_CONTACT);
    const [deleteContactMutation] = useMutation(QUERIES.DELETE_COLT_CONTACT);

    useEffect(()=>{
        if(data&&data.coltContacts)
            setContacts(data.coltContacts);
    },[data]);

    useEffect(()=>{
        setLoading(queryLoading);
    },[queryLoading]);

    if(!!currentEmployee&&!["ADMINISTRATOR"].includes(currentEmployee.securityRole.code)){
        history.push("/");
    }

    const deleteContact=(row)=>{
        confirmAlert({
            title: row.Email,
            message: 'Bent u zeker om dit contact te verwijderen?',
            buttons: [
              {
                label: 'Ja',
                onClick: async() =>{
                    try{
                        setLoading(true);
                        await deleteContactMutation({variables:{coltContact: {
                            id: row.id,
                            Email: row.Email
                        }}});
                        await refetch();
                        showInfoToast(`${row.Email} succesvol verwijderd.`);
                    }catch(err){
                        showErrorToast(`Er ging iets mis bij het verwijderen van ${row.Email}`);
                    }
                    setSelectedAndDialog(null, false);
                    setLoading(false);
                }
              },
              { label: 'Nee' }
            ]
          });
    }

    const createContact=async(Email)=>{
        try{
            setLoading(true);
            await createContactMutation({variables:{
                coltContact:{
                    Email
                }
            }});
            await refetch();
            showInfoToast(`${Email} succesvol toegevoegd.`);
        }catch(err){
            showErrorToast(`Er ging iets mis bij het aanmaken van ${Email}`);
        }
        setSelectedAndDialog(null, false);
        setLoading(false);
    }

    const updateContact=async(newEmail)=>{
        try{
            setLoading(true);
            await updateContactMutation({variables:{
                coltContact:{
                    id: selectedContact.id,
                    Email: newEmail
                }
            }});
            await refetch();
            showInfoToast(`${newEmail} succesvol toegevoegd.`);
        }catch(err){
            showErrorToast(`Er ging iets mis bij het aanmaken van ${newEmail}`);
        }
        setSelectedAndDialog(null, false);
        setLoading(false);
    }

    const setSelectedAndDialog=(selected, dialogState)=>{
        setSelectedContact(selected);
        setContactDialogVisible(dialogState);
    }

    const cols = [
        { field: 'Email', headerName: 'Email', width: "80vw", renderCell: ({row})=>{
            return(
                <div style={{ width: "100%", height: "100%", padding: 0, margin: 0 }}>
                    <p style={{ float: "left", height: "100%", marginTop: 0 }}>{row.Email}</p>
                    <strong style={{ float: "right" }}><ButtonBase style={{ margin: "auto", borderRadius: "25px" }} onClick={()=>setSelectedAndDialog(row, true)} ><Avatar className={classes.avatar}>
                        <EditIcon style={{color: "black"}} />
                    </Avatar></ButtonBase></strong>
                </div>
            )
        } },
        { field: 'id', headerName: 'Verwijderen', width: "9vw", renderCell: ({row})=>{
            return (
                <ButtonBase style={{ margin: "auto", borderRadius: "25px" }} onClick={()=>deleteContact(row)} ><Avatar className={classes.avatar}>
                        <DeleteIcon style={{color: "black"}} />
                </Avatar></ButtonBase>
            )
        } },
    ]


    return (
    <>
    <Grid item style={{marginTop: 20, marginBottom: 10}}>
        <Typography style={{float: "left", marginLeft: "5vw"}} variant='h3'>Contacten</Typography>
        <IconButton style={{float: "left", marginLeft: 25, marginTop: 5, background: "rgba(144, 238, 144, 0.2)"}} onClick={() => setSelectedAndDialog(null, true)}>
            <AddIcon />
        </IconButton>
    </Grid>
    <Grid item style={{margin:"auto"}}>
        <div style={{ height: '80vh', width: '90vw' }}>
            <DataGrid key={contacts?contacts:[]} columns={cols} rows={[...contacts]} sortModel={[{ field: 'Email', sort: 'asc' }]} ></DataGrid>
        </div>
    </Grid>

    <ContactsDialog contactDialogVisible={contactDialogVisible} selectedContact={selectedContact} setSelectedAndDialog={setSelectedAndDialog} createContact={createContact} updateContact={updateContact} />

    <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
    </Backdrop>
    </>
    )
}

export default ContactsPage;