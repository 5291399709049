const { toast } = require("react-toastify");


const toastConfig =  {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
};

export const showInfoToast=(msg)=>{
    toast.info(msg, toastConfig);
}

export const showErrorToast=(msg)=>{
    toast.error(msg, toastConfig);
}

