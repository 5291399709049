import './App.css';
import {useEffect, useState} from 'react';
import { Grid } from '@material-ui/core';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
// import {InMemoryCache} from 'apollo-boost';
import PrivateRoute from './auth/PrivateRoute';
import Login from './components/Login/Login'
import HeaderMenu from './components/Navigation/HeaderMenu';
import CreatePage from './components/CreatePage/CreatePage';
import ReportPage from './components/ReportPage/ReportPage';
import SendPage from './components/SendPage/SendPage';
import { useGlobal } from 'reactn';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ColtDrawer from './components/Navigation/ColtDrawer';
import ContactsPage from './components/ContactsPage/ContactsPage';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const App = ()=>  {

  const [authUser, setAuthUser] = useGlobal('authUser');
  const [userGroups, setUserGroups] = useGlobal('userGroups');
  const [client, setClient] = useState(null);
  const [showDrawer, setShowDrawer] = useState(false);

  const defaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
  }

  useEffect(() => {
    console.log(process.env.NODE_ENV);
    let httpLink = createHttpLink({
      // uri: 'https://api-33k7vpwdbq-ew.a.run.app/',
      uri: process.env.NODE_ENV === 'development'? 'http://localhost:8080': 'https://api-33k7vpwdbq-ew.a.run.app/',
      // credentials: 'same-origin',
      headers: {
        'authorization': authUser? authUser.tokenId: '',
        'accessToken': authUser? authUser.accessToken: ''
      }
    });

    let client = new ApolloClient({
      cache: new InMemoryCache(),
      link: httpLink,
      defaultOptions: defaultOptions
    });

    setClient(client);
  }, [authUser]);

  if (!client) {
    return null;
  }

  const toggleDrawer=(val)=>{
    const state = val?val:!showDrawer;
    setShowDrawer(state);
  }
  
  return (
    <ApolloProvider client={client}>
      <Router>
        <Grid
        container
        direction="column"
        justify="center"
        alignItems="flex-start">
          <HeaderMenu toggleDrawer={toggleDrawer} toggleDrawer={toggleDrawer} />
          <Switch>
          <Route path='/login' exact component={Login}/>
          <PrivateRoute path='/' exact component={CreatePage}/>
          <PrivateRoute path='/Send' exact component={SendPage}/>
          <PrivateRoute path='/Report' exact component={ReportPage}/>
          <PrivateRoute path='/Contacts' exact component={ContactsPage}/>
        </Switch>
        </Grid>

        <ColtDrawer showDrawer={showDrawer} toggleDrawer={toggleDrawer} />
      <ToastContainer />
      </Router>
      </ApolloProvider>
  );
}

export default App;
