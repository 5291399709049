import { useQuery } from '@apollo/client';
import { getGlobal, useGlobal } from 'reactn';
import { Backdrop, CircularProgress, Grid, Typography, makeStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { QUERIES } from '../../api/queries';
import SheetTable from '../SheetTable/SheetTable';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

const SendPage = props => {
    const classes = useStyles();
    const history = useHistory();

    const statusOptions = ['Te Verzenden', 'Verzonden'];
    const [currentEmployee] = useGlobal('currentEmployee');
    const [loading, setLoading] = useState(false);

    const sheetRes = useQuery(QUERIES.SHEETS_FOR_STATUS, {
        variables: { statuses: statusOptions }
    })
    const groupRes = useQuery(QUERIES.GROUPS_FOR_USER, {
        variables: {
            user: getGlobal().authUser ? getGlobal().authUser.getBasicProfile().getEmail() : null
        }
    })
    useEffect(() => {
        console.log(groupRes.data)
    }, [groupRes.data])

    if (sheetRes.loading && groupRes.loading) {
        return (
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                spacing={4}>
                <Grid item>
                    <Typography variant='h2'>Verzenden</Typography>
                    <CircularProgress />
                </Grid>
            </Grid>)
    }
    
    if(!!currentEmployee&&!["ADMINISTRATOR", "PROJECT_MANAGER"].includes(currentEmployee.securityRole.code)){
        history.push("/");
    }

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={4}>
            <Grid item>
                <Typography variant='h2'>Verzenden</Typography>
                {/* <Dialog open={projectDetailsVisible}>
                <ProjectDetails
                    visible={projectDetailsVisible}
                    projectId={selectedProject? selectedProject.id: null}
                    handleClose={() => setProjectDetailsVisible(false)}
                    handleSave={async () => {
                        setProjectDetailsVisible(false)
                        // refetch().then(res => {
                        //     console.log('Refetch res: ', res)
                        //     handleData(res.data);
                        // })
                        }} />
            </Dialog> */}
            </Grid>
            {!!sheetRes.data && (
                <Grid item>
                    <SheetTable sheets={sheetRes.data.coltSheets} statusOptions={statusOptions} showSendPageContent={true} setSendLoading={setLoading}/>
                </Grid>
            )}

            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Grid>
    )
}

export default SendPage;